import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCustomers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios                
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/customers`, { params: queryParams })                                 
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        fetchCustomer(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/customer/${id}/edit`)
                .then(response => resolve(response))
                .catch(error => reject(error));
            })
        },
        addCustomer(ctx, customerData) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                
                formData.append("logo", customerData.logo);
                Object.entries(customerData).map(item => {
                    if (item[0] !== "logo") {
                        formData.append(item[0], item[1]);
                    }
                });
                
                axios
                .post(`${process.env.VUE_APP_HOST}/api/dashboard/customer/create`, formData, {
                    headers: { 
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => resolve(response))
                .catch(error => reject(error));
            })
        },
        updateCustomer (ctx, { id, customerData }) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                
                formData.append("name", customerData.name);
                formData.append("ruc", customerData.ruc);
                formData.append("logo", customerData.logo);
                formData.append("changeLogo", customerData.changeLogo);
                formData.append("initLogo", customerData.initLogo);
        
                axios
                .put(`${process.env.VUE_APP_HOST}/api/dashboard/customer/${id}/update`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data'}
                })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        deleteCustomer (ctx, { id }){
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/customer/${id}/delete`)
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        }
    },
}
