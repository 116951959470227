<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
            ref="previewEl"
            :src="avatarSrc(userData.logo)"
            :text="avatarText(userData.name)"
            variante="light-success"
            size="90px"
            rounded
        />
      </template>
      <h4 class="mb-0 ml-50">
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap mt-1">
        <b-button
          variant="primary"
          class="btn-sm"
          @click="$refs.refInputEl.click()"
        >
          <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @change="onFileChange"
            >
            <span class="d-none d-sm-inline">Actualizar</span>
          <feather-icon
              icon="EditIcon"
              class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
            @click="removeAvatar"
            variant="outline-secondary"
            class="ml-1 btn-sm"
          >
          <span class="d-none d-sm-inline">Remover</span>
            <feather-icon
                icon="TrashIcon"
                class="d-inline d-sm-none"
            />
        </b-button>
      </div>
    </b-media>


    <!-- Form: Info Form -->
    <b-form class="mt-1">
      <b-row>
        <!-- Nombre -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="userData.name"
              type="text"
            />        
          </b-form-group>   
        </b-col>
        
        <!-- RUC -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="RUC"
            label-for="ruc"
          >
            <b-form-input
              id="ruc"
              v-model="userData.ruc"
              type="text"
            />       
          </b-form-group>       
        </b-col>
      </b-row>    
    </b-form>
    
    <!-- Action Buttons -->
    <b-button
      @click="updateInformation"
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Guardar cambios
    </b-button>      
  </div>
</template>

<script>
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import vSelect from 'vue-select';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import store from '@/store';

export default {
  components: {
      BButton,
      BMedia,
      BAvatar,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BForm,
      vSelect,
  },
  
  props: {
      userData: {
          type: Object,
          required: true,
      }
  },

  methods: {
    avatarSrc (img){
      if (img){
          if (img.includes('http')){
              return img;
          } else {
              if (process.env.VUE_APP_ENVIROMENT == 'development'){
                  return require(`@/assets/images/modules/customers/${img}`);
              } else {
                  return `${process.env.VUE_APP_FILES}/modules/customers/${img}`;
              }
          }
      } else {
          return null;
      }
    }
  },
  
  setup(props) {
    
      // Init Logo
      const initLogo = props.userData.logo;
      
      // Use Toast
      const toast = useToast();
      
      const blankData = {      
        logo : null,
        initLogo
      }
      
      // ? Demo Purpose => Update image on click of update
      const refInputEl = ref(null);
      const previewEl = ref(null);      
      
      const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
        props.userData.logo = base64;
      })
      
      const onFileChange = (e) => {
          const file = e.target.files[0];          
          props.userData.changeLogo = 1;
          if (file) {
              props.userData.logo = URL.createObjectURL(file);
              blankData.logo = file;                                          
          } else {
              props.userData.logo = null;
              blankData.logo = null;
          }          
      };
            
      const updateInformation = () => {
          let customerData = {
              ...props.userData,
              ...blankData
          };
          
          store.dispatch('app-user/updateCustomer', { id: props.userData.id, customerData})
          .then( (response) => {
             
              props.userData.name = response.data.customer.name;
              props.userData.logo = response.data.logo;
              blankData.initLogo = response.data.logo;
              props.userData.changeLogo = 0;
              
              
              toast({
                  component: ToastificationContent,
                  props: {
                      title: response.data.message,
                      icon: 'CheckIcon',
                      variant: 'success'
                  }
              });
          })
          .catch( (err) => {
              let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del cliente';
              toast({
                  component: ToastificationContent,
                  props: {
                      title: message,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger'
                  }
              });
          });
      };

      const removeAvatar = () => {
          props.userData.logo = null;
          blankData.logo = null;
          props.userData.changeLogo = 1;
      };
      
      return {
        avatarText,
        updateInformation,
        removeAvatar,
        onFileChange, 
        
        refInputEl,
        previewEl,
        inputImageRenderer,
      }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
